import * as React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import {
  root,
  container,
  heading,
  navLinks,
  navLinkDivider,
  navLinkText,
  blogLinkText,
} from './layout.module.css'

const Layout = ({ pageTitle, children }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        nodes {
          frontmatter {
            slug
            short
          }
        }
      }
    }
  `)
  return (
    <div className={root}>
      <div className={container}>
        <nav>
          <ul className={navLinks}>
            <li>
              <Link to="/">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className={navLinkText}>
                About
              </Link>
            </li>
            <li className={navLinkDivider}>
              Recent Posts →
            </li>
            {
              data.allMarkdownRemark.nodes.map(({frontmatter}) => {
                console.log(frontmatter);
                return (
                  <li key={frontmatter.short}>
                    <Link to={frontmatter.slug} className={blogLinkText}>
                      {frontmatter.short}
                    </Link>
                  </li>
                )
              })
            }
          </ul>
        </nav>
        <main>
          <h1 className={heading}>{pageTitle}</h1>
          {children}
        </main>
      </div>
    </div>
  )
}

export default Layout
